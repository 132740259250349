<script setup lang="ts">
import { CfgButton, CfgTab, CfgTabPanel, CfgTabs, CfgTypography, CfgTypographySizes } from '@cfgtech/ui'
import type { DeepReadonly } from 'vue'
import { getIssuesList } from '~/api/modules/issues/services/getIssuesList/getIssuesList.service'
import { IssuesCollateralType } from '~/api/modules/issues/services/getIssuesList/getIssuesList.types'
import { IssueSortTypes, type IssuesListFilters } from '~/composables/issues/useIssuesList/types'

type FetchBestIssuesFilters = {
  filters: DeepReadonly<Partial<IssuesListFilters>>
  sort?: {
    key: IssueSortTypes
    direction: 'asc' | 'desc'
  }
  includeDefaultSort: boolean
}

async function fetchBestIssues(fetchBestIssuesFilters: FetchBestIssuesFilters = { filters: {}, includeDefaultSort: true }) {
  return await getIssuesList({
    filters: fetchBestIssuesFilters.filters,
    pagination: {
      limit: 6,
    },
    ...(fetchBestIssuesFilters.sort ? { sort: { key: fetchBestIssuesFilters.sort.key, direction: fetchBestIssuesFilters.sort.direction } } : {}),
    includeDefaultSort: fetchBestIssuesFilters.includeDefaultSort,
  })
}

const [mostViewsIssues, bestInterestRateIssues, mostCollateralsIssues, collateralPropertiesIssues, almostSoldIssues] = await Promise.all([
  // most views
  fetchBestIssues({
    filters: {
      isNotArchived: true,
    },
    sort: {
      key: IssueSortTypes.VIEWS,
      direction: 'desc',
    },
    includeDefaultSort: false,
  }),
  // best interest rate
  fetchBestIssues({
    filters: {
      isNotArchived: true,
    },
    sort: {
      key: IssueSortTypes.ANNUAL_INTEREST_RATE,
      direction: 'desc',
    },
    includeDefaultSort: true,
  }),
  // most collaterals
  fetchBestIssues({
    filters: {
      isNotArchived: true,
    },
    sort: {
      key: IssueSortTypes.COLLATERALS,
      direction: 'desc',
    },
    includeDefaultSort: true,
  }),
  // has properties
  fetchBestIssues({
    filters: {
      collateralTypes: [IssuesCollateralType.properties],
      isNotArchived: true,
    },
    includeDefaultSort: true,
  }),
  // almost sold
  fetchBestIssues({
    filters: {
      isAvailable: true,
      isNotArchived: true,
    },
    sort: {
      key: IssueSortTypes.AVAILABLE_COUNT,
      direction: 'asc',
    },
    includeDefaultSort: false,
  }),
])

const selectedTab = ref(0)

const { t } = useI18n()

const sections = computed(() => [
  {
    label: t('issue.bestCard.sections.mostViews.title'),
    hint: t('issue.bestCard.sections.mostViews.hint'),
    issues: mostViewsIssues.data,
  },
  {
    label: t('issue.bestCard.sections.bestInterestRate.title'),
    hint: t('issue.bestCard.sections.bestInterestRate.hint'),
    issues: bestInterestRateIssues.data,
  },
  {
    label: t('issue.bestCard.sections.mostCollaterals.title'),
    hint: t('issue.bestCard.sections.mostCollaterals.hint'),
    issues: mostCollateralsIssues.data,
  },
  {
    label: t('issue.bestCard.sections.collateralProperties.title'),
    hint: t('issue.bestCard.sections.collateralProperties.hint'),
    issues: collateralPropertiesIssues.data,
  },
  {
    label: t('issue.bestCard.sections.almostSold.title'),
    hint: t('issue.bestCard.sections.almostSold.hint'),
    issues: almostSoldIssues.data,
  },
])

const responsive = useResponsive()
</script>

<template>
  <CfgTabs
    v-if="responsive.desktop"
    v-model:selectedTab="selectedTab"
    class="w-full"
  >
    <template #tabs>
      <div class="flex justify-center gap-x-14">
        <template
          v-for="section in sections"
          :key="section.label"
        >
          <CfgTab v-if="section.issues.length">
            <CfgTypography :size="CfgTypographySizes.md">
              {{ section.label }}
            </CfgTypography>
          </CfgTab>
        </template>
      </div>
    </template>

    <template #panels>
      <template
        v-for="section in sections"
        :key="section.label"
      >
        <CfgTabPanel v-if="section.issues.length" class="flex w-full flex-col items-center">
          <div class="mt-10 flex w-full max-w-[1000px] flex-wrap justify-center gap-5">
            <IssueBestIssuesCard
              v-for="issue in section.issues"
              :key="issue.id"
              :issue="issue"
            />

            <IssueBestIssuesHint class="mt-6" :hint="section.hint" />

            <NuxtLink class="mx-4 mt-10 flex" to="/dluhopisy/firemni">
              <CfgButton
                as="span"
                variant="primary"
              >
                {{ $t('issue.bestCard.button.showMore.label') }}
              </CfgButton>
            </NuxtLink>
          </div>
        </CfgTabPanel>
      </template>
    </template>
  </CfgTabs>

  <div v-else class="flex flex-col">
    <IssueBestIssuesMobile
      v-for="section in sections"
      :key="section.label"
      class="mb-10"
      :issues="section.issues"
      :title="section.label"
    />

    <NuxtLink class="mx-4 mt-10 flex" to="/dluhopisy/firemni">
      <CfgButton
        as="span"
        class="w-full"
        variant="primary"
      >
        {{ $t('issue.bestCard.button.showMore.label') }}
      </CfgButton>
    </NuxtLink>

    <IssueBestIssuesHint class="mt-4 px-4" :hint="$t('issue.bestCard.defaultHint')" />
  </div>
</template>
