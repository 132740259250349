<script setup lang="ts">
import { ArrowRightIcon, Calendar2Icon, DiagramIcon } from '@cfgtech/icons'
import { CfgHint, CfgTypography, CfgTypographySizes } from '@cfgtech/ui'
import dayjs from 'dayjs'
import type { ShortIssueWithId } from '~/api/modules/issues/services/getIssuesList/getIssuesList.types'
import { ISSUE_URL } from '~/constants/issue'

const props = defineProps<{
  issue: ShortIssueWithId
}>()

const { interestRate, interestRateTooltip } = useIssueVariableYield({
  variableYieldInterestRateType: props.issue.interestRate?.variableYieldInterestRateType,
  variableYieldMargin: props.issue.interestRate?.variableYieldMargin,
  annualInterestRate: !props.issue.interestRate?.annualInterestRate
    ? ['-']
    : props.issue.interestRate.annualInterestRate.toFixed(2)?.split?.('.') || ['0', '0'],
  yieldType: props.issue.interestRate?.yieldType,
  annualInterestRateNote: props.issue.interestRate?.annualInterestRateNote,
})

const issueMaturityDateFromNow = computed(() => {
  const date = props.issue?.maturityDate || props.issue?.payoutDate

  return date ? dayjs(date).fromNow() : '-'
})
</script>

<template>
  <NuxtLink
    aria-label="bestIssueCard"
    class="block"
    :to="{
      path: `/${ISSUE_URL}/${issue.slug}`,
      query: {
        ...(issue.company.id && { companyId: issue.company.id }),
      },
    }"
  >
    <div class="flex w-[290px] flex-col rounded-xl border border-grey-100 p-5 md:w-[320px]">
      <div class="flex justify-between text-brand">
        <CfgTypography class="line-clamp-2 block truncate" :size="CfgTypographySizes.subtitle">
          {{ issue.company.name }}
        </CfgTypography>

        <ArrowRightIcon class="overflow-visible text-[24px]" />
      </div>

      <div class="mt-3 grid grid-cols-2">
        <div class="flex gap-x-3">
          <DiagramIcon class="mt-1 text-[20.5px] text-grey-400" />

          <span class="flex flex-col">
            <CfgHint
              :disabled="!interestRateTooltip"
              hide-icon
              interactive
            >
              <template #trigger>
                <div class="flex flex-col gap-y-4">
                  <div class="flex h-4">
                    <CfgTypography
                      class="!font-extrabold"
                      :html="interestRate"
                      :size="CfgTypographySizes.subtitle"
                      tag-name="p"
                    />

                    <span v-if="interestRateTooltip">
                      *
                    </span>
                  </div>

                  <CfgTypography
                    class="text-secondary"
                    :size="CfgTypographySizes.small"
                    tag-name="p"
                  >
                    {{ $t('issue.bestCard.interestRate') }}
                  </CfgTypography>
                </div>
              </template>

              <template #content="{ initialized }">
                <!-- eslint-disable-next-line vue/no-v-html -->
                <p v-if="initialized" v-html="interestRateTooltip" />
              </template>
            </CfgHint>
          </span>
        </div>

        <div class="mt-1 flex flex-col">
          <IssueScore
            class="flex gap-x-1"
            :collaterals="issue.collaterals"
            :dmat-checks="issue.dmatChecks"
          />

          <CfgTypography
            class="mt-1 text-secondary"
            :size="CfgTypographySizes.small"
          >
            {{ $t('issue.bestCard.collaterals') }}
          </CfgTypography>
        </div>
      </div>

      <div class="mt-4 flex gap-x-2">
        <Calendar2Icon class="text-[24px] text-grey-400" />

        <CfgTypography :size="CfgTypographySizes.sm">
          {{ $t('issue.bestCard.maturityDate', { from: issueMaturityDateFromNow }) }} {{ issue.hasEarlyRepayment ? $t('issue.bestCard.hasEarlyRepayment') : '' }}
        </CfgTypography>
      </div>
    </div>
  </NuxtLink>
</template>
